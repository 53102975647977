import React from "react"
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import * as styles from '../styles/home.module.css'

export default function Home() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Grinply - Applying AI to develop applications of tomorrow</title>
        <link rel="canonical" href="http://grinply.com/" />
      </Helmet>

      <Layout>
        <section>
          <h1>
            Applying <span>AI</span> to develop <span>applications</span> of tomorrow.
          </h1>
          <div>
            <p>We create <strong>software solutions</strong> leveraging the power of <strong>AI and deep 
              learning.</strong> Our mission is to provide a seamless interaction with AI systems to open automation opportunities.
            </p>
          </div>
        </section>

        <section className={styles.neuralnet}>
          <svg width="401" height="268" viewBox="0 0 401 268" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M348.189 236.346L211.024 230.016" stroke="#9B9898" />
            <path d="M348.189 236.346L249.008 88.6299" stroke="#9B9898" />
            <path d="M348.189 25.3228L249.008 88.6299" stroke="#9B9898" />
            <path d="M375.622 132.945L249.008 88.6299" stroke="#9B9898" />
            <path d="M375.622 132.945L217.354 236.346" stroke="#9B9898" />
            <path d="M348.189 33.7638L217.354 236.346" stroke="#9B9898" />
            <path d="M249.008 97.0709L217.354 236.346" stroke="#9B9898" />
            <path d="M141.386 158.268L217.354 236.346" stroke="#9B9898" />
            <path d="M145.606 37.9842L217.354 230.016" stroke="#9B9898" />
            <path d="M145.606 37.9843L249.008 88.6299" stroke="#9B9898" />
            <path d="M145.606 155.102L250.063 88.6299" stroke="#9B9898" />
            <path d="M141.386 149.827V46.4252" stroke="#9B9898" />
            <path d="M44.315 221.575L90.7402 129.78L137.165 37.9842" stroke="#9B9898" />
            <path d="M31.6535 103.402L81.7717 70.6929L131.89 37.9842" stroke="#9B9898" />
            <path d="M44.315 221.575L94.4331 188.866L144.551 156.157" stroke="#9B9898" />
            <path d="M27.4331 102.346L138.22 149.827" stroke="#9B9898" />
            <circle cx="26.378" cy="100.236" r="26.378" fill="#F8B200" />
            <circle cx="142.441" cy="39.0394" r="26.378" fill="#F8B200" />
            <circle cx="374.567" cy="134" r="26.378" fill="#55D698" />
            <circle cx="250.063" cy="85.4646" r="26.378" fill="#F8B200" />
            <circle cx="347.134" cy="26.378" r="26.378" fill="#00CBF8" />
            <circle cx="347.134" cy="241.622" r="26.378" fill="#FB7021" />
            <circle cx="41.1496" cy="222.63" r="26.378" fill="#F8B200" />
            <circle cx="142.441" cy="152.992" r="26.378" fill="#F8B200" />
            <circle cx="212.079" cy="231.071" r="26.378" fill="#F8B200" />
          </svg>
        </section>
      </Layout>
    </React.Fragment>

  )
}
